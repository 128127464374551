const fontSizes = {
  fontSize20: { fontSize: "20px" },
  fontSize22: { fontSize: "22px" },
  fontSize24: { fontSize: "24px" },
  fontSize26: { fontSize: "26px" }
};

const state = {
  fontSize: 20
};

const mutations = {
  SET_FONT_SIZE: function(state) {
    const fontSize = state.fontSize + 2;
    state.fontSize = fontSize > 26 ? 20 : fontSize;
  }
};
const actions = {};
const getters = {
  fontSize: state => {
    return fontSizes["fontSize" + state.fontSize];
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
