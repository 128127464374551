const state = {
  solorLayerRootfTopsVisible: true,
  openSpaceSolarLayerVisible: true,
  buildingLayerVisible: true,

  solarOpenSpaceVisible: true,
  openSpaceShpVisible: true,

  // Solar Panel Efficiency default value
  panelEfficencyValue: 20,
  tiltingAngleValue: 0,
  orientationValue: 180,
  transparencyValue: 0
};

const mutations = {
  set_solor_rooftops_visible: (state, value) => {
    state.solorLayerRootfTopsVisible = value;
  },
  set_building_layer_visible: (state, buildingLayerVisible) => {
    state.buildingLayerVisible = buildingLayerVisible;
  },

  set_open_space_shp_visible: (state, openSpaceShpVisible) => {
    state.openSpaceSolarLayerVisible = openSpaceShpVisible;
  },

  set_panel_efficency_value: (state, panelEfficencyValue) => {
    state.panelEfficencyValue = panelEfficencyValue;
  },
  set_tilting_angle_value: (state, tiltingAngleValue) => {
    state.tiltingAngleValue = tiltingAngleValue;
  },
  set_orientation_value: (state, orientationValue) => {
    state.orientationValue = orientationValue;
  },
  set_transparency_value: (state, transparencyValue) => {
    state.transparencyValue = transparencyValue;
  }
};
const actions = {};
const getters = {
  solorLayerVisible: state => {
    return state.solorLayerVisible;
  },
  buildingLayerVisible: state => {
    return state.buildingLayerVisible;
  },
  panelEfficencyValue: state => {
    return state.panelEfficencyValue;
  },
  tiltingAngleValue: state => {
    return state.tiltingAngleValue;
  },
  orientationValue: state => {
    return state.orientationValue;
  },
  transparencyValue: state => {
    return state.transparencyValue;
  },
  solorLayerRootfTopsVisible: state => {
    return state.solorLayerRootfTopsVisible;
  },
  openSpaceSolarLayerVisible: state => {
    return state.openSpaceSolarLayerVisible;
  },
  openSpaceShpVisible: state => {
    return state.openSpaceShpVisible;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
