import logoCN from "@/assets/images/logo-cn.jpg";

export default {
  systemLogo: logoCN,
  reNetLink: "https://re.emsd.gov.hk/sc_chi/index.html",
  systemMenus: [
    { index: "1", name: "主页", icon: "shouye" },
    { index: "2", name: "联系我们", icon: "mail" },
    { index: "3", name: "公告", icon: "Disclaimer" }
  ],
  systemTitle: "香港太阳辐照图",
  subTitle: "适用于建筑物屋顶及公众空间",
  // guidelineLink: "https://hk-icon.oss-cn-hongkong.aliyuncs.com/guideline_chinese_s.pdf",
  guidelineLink: "./files/guideline_sc.pdf",
  payback_card: {
    title: "回报计算器",
    installation_capacity_label: "发电容量 (千瓦)",
    installation_capacity_placeholder: "输入",
    installation_cost_label: "安装成本 (元)",
    installation_cost_placeholder: "输入",
    annual_electricity_generation: "预计每年发电量",
    income: "预计每年上网电价收入",
    payback_period: "预计回报周期 (年)"
  },
  solar_result_card: {
    title: "所选范围计算结果",
    area_selected: "所选范围面积",
    area_suitable_for_PV_installation: "适合安装太阳能板面积",
    installation_capacity: "预计发电容量",
    electricity_generation: "预计每年发电量",
    Fit_income: "预计每年上网电价收入",
    payback_button: "回报计算器"
  },
  search: {
    placeholder: "请输入搜索的地点",
    address: "地址",
    place_name: "地点名称"
  },
  topographic_map_label: {
    lang: "sc",
    layer_title: "数字地图注记"
  },
  topographic_map: {
    layer_title: "数字地图",
    attributions:
      "<a class='attr-text' href='https://api.portal.hkmapservice.gov.hk/disclaimer' target='_blank'>&copy; 地图由地政总署提供</a><div class='attr-logo'></div>"
  },
  satelite_map: {
    layer_title: "数字影像地图",
    attributions:
      "<a class='attr-text' href='https://api.portal.hkmapservice.gov.hk/disclaimer' target='_blank'>&copy; 航空照片由地政总署提供</a><div class='attr-logo'></div>"
  },
  draw_tool: {
    title: "绘图及计算",
    rec: "矩形",
    polygon: "绘图",
    clear: "清除"
  },
  solor_layer_switcher: {
    transparency: "太阳辐照图层透明度",
    // transparency: "透明度",
    transparency_placeholder: "请选择太阳辐照图层透明度",
    title: "选项",
    solar_layer_switch_building: "太阳辐照图层 (建筑物屋顶)",
    solar_layer_switch_open_space: "太阳辐照图层 (公众空间)",
    building_layer_switch: "建筑物图层",
    panel_type: "太阳能板类型",
    panel_efficency: "太阳能板效率",
    tilting_angle: "太阳能板倾斜角度",
    orientation: "太阳能板方向",
    panel_type_placeholder: "请选择太阳能板类型",
    panel_efficency_placeholder: "20 (预设)",
    tilting_angle_placeholder: "请选择太阳能板倾斜角度",
    orientation_placeholder: "请选择太阳能板方向",
    north: "北",
    west: "西",
    east: "东",
    south: "南 (预设)",
    northeast: "东北",
    southeast: "东南",
    southwest: "西南",
    northwest: "西北",
    default: " (预设)"
  },
  legend: {
    title: "图例",
    unit: "KWh/m²",
    excellent: "极佳 （>1400千瓦时/平方米）",
    good: "好 （1200-1400千瓦时/平方米）",
    fair: "普通 （800-1200千瓦时/平方米）",
    poor: "差 （<800 千瓦时/平方米）"
  },
  basemap: {
    title: "选择地图",
    shp: "地形图",
    raster: "正射影像图"
  },
  message: {
    error: "出错了！请检查您的网络并稍后重试。",
    drawError: "出错了！请检查您绘制的图形,它必须在建筑物或公众空间内部。",
    alertMessage: {
      message: `
      本網站列載的資料由中華人民共和國香港特別行政區政府（"政府"）轄下的機電工程署編製，只供一般參考。對於因或就本網站所載的任何資料（包括數據或程式）而引起的任何損失或損害，政府並不承擔責任。政府保留權利，可隨時運用其絕對酌情決定權，省略、暫停或編輯本網站所載由政府編製的資料，而無須給予任何理由，亦無須事先通知。使用者有責任自行評估本網站所載的一切資料，並宜加以核實，在根據該等資料行事之前徵詢獨立意見。
      <br />
      <br />
      The information contained in this website is compiled by the Electrical and Mechanical Services Department of the Government of the Hong Kong Special Administrative Region of the People's Republic of China (“the Government”) for general information only. The Government is not responsible for any loss or damage whatsoever arising out of or in connection with any information including data or programmes on this website. The Government reserves the right to omit, suspend or edit all information compiled by the Government in this website at any time in its absolute discretion without giving any reason or prior notice. Users are responsible for making their own assessment of all information contained in this website and are advised to verify such information by obtaining independent advice before acting upon it.
      `,
      confirmTitle: "免責聲明 Disclaimer",
      confirmButtonText: "我接受 (I accept)",
      cancelButtonText: "我不接受 (I do not accept)",
      confirmButtonTextWithoutChinese: "OK"
    },
    containZero: "你画的范围里有一些不适合安装太阳能系统的地方。",
    notNumber: "输入非法，请输入数字",
    capacityError: "你所输入的安装容量大于预计安装容量。",
    village:
      "如果这是新界豁免管制屋宇(俗称'村屋')，太阳能发电系统的安装应参考地政总署发出的《兴建新界豁免管制屋宇须知》或屋宇署发出的《无僭建村屋安居又幸福》小册子中规定的要求。",
    fitIncome: "预计发电容量大于1 MW，请联系电力公司商讨上网电价。"
  },
  buildingPopup: {
    totalRoofArea: "建筑屋顶总面积",
    areaSuitableForInstallation: "适合安装太阳能板面积",
    averageSolar: "年均太阳辐照量",
    installationCapacity: "预计发电容量",
    expectedAnnualElectricityGeneration: "预计每年发电量",
    expectedAnnualFiTIncome: "预计每年上网电价收入",
    title: "建筑物资料 ",
    info:
      "下表中显示的信息是基于效率百分之20的太阳能板于0度下的计算结果，有关其他角度的详细结果，请使用绘制。",
    openSpaceTitle: "公众空间资料",
    openSpaceArea: "公众空间总面积",
    openSpaceType: "类型"
  },
  units: {
    m2: "平方米",
    "kWh/m2": "千瓦时/平方米",
    kW: "千瓦",
    kWh: "千瓦时",
    hk$: "元"
  },
  north: "北",
  noOpenSpace: "其他公众空间的太阳能板倾斜角度选项即将推出。"
};
