import logoCN from "@/assets/images/logo-cn.jpg";

export default {
  systemLogo: logoCN,
  reNetLink: "https://re.emsd.gov.hk/tc_chi/index.html",
  systemMenus: [
    { index: "1", name: "主頁", icon: "shouye" },
    { index: "2", name: "聯絡我們", icon: "mail" },
    { index: "3", name: "公告", icon: "Disclaimer" }
  ],
  systemTitle: "香港太陽輻照圖",
  subTitle: "適用於建築物屋頂及公眾空間",
  // guidelineLink: "https://hk-icon.oss-cn-hongkong.aliyuncs.com/guideline_chinese_t.pdf",
  guidelineLink: "./files/guideline_tc.pdf",
  payback_card: {
    title: "回報計算器",
    installation_capacity_label: "發電容量 (千瓦)",
    installation_capacity_placeholder: "輸入",
    installation_cost_label: "安裝成本 (元)",
    installation_cost_placeholder: "輸入",
    annual_electricity_generation: "預計每年發電量",
    income: "預計每年上網電價收入",
    payback_period: "預計回報周期 (年)"
  },
  solar_result_card: {
    title: "所選範圍計算結果",
    area_selected: "所選範圍面積",
    area_suitable_for_PV_installation: "適合安裝太陽能板面積",
    installation_capacity: "預計發電容量",
    electricity_generation: "預計每年發電量",
    Fit_income: "預計每年上網電價收入",
    payback_button: "回報計算器"
  },
  search: {
    placeholder: "請輸入搜索的地點",
    address: "地址",
    place_name: "地點名稱"
  },
  topographic_map_label: {
    lang: "tc",
    layer_title: "數字地圖註記"
  },
  topographic_map: {
    layer_title: "地形圖",
    attributions:
      "<a class='attr-text' href='https://api.portal.hkmapservice.gov.hk/disclaimer' target='_blank'>&copy; 地圖由地政總署提供</a><div class='attr-logo'></div>"
  },
  satelite_map: {
    layer_title: "數字影像地圖",
    attributions:
      "<a class='attr-text' href='https://api.portal.hkmapservice.gov.hk/disclaimer' target='_blank'>&copy;  航空照片由地政總署提供</a><div class='attr-logo'></div>"
  },
  draw_tool: {
    title: "繪圖及計算",
    rec: "矩形",
    polygon: "繪圖",
    clear: "清除"
  },
  solor_layer_switcher: {
    transparency: "太陽輻照圖層透明度",
    // transparency: "透明度",
    transparency_placeholder: "請選擇太陽輻照圖層透明度",
    title: "選項",
    solar_layer_switch_building: "太陽輻照圖層 (建築物屋頂)",
    solar_layer_switch_open_space: "太陽輻照圖層 (公眾空間)",
    building_layer_switch: "建築物圖層",
    panel_type: "太陽能板類型",
    panel_efficency: "太陽能板效率",
    tilting_angle: "太陽能板傾斜角度",
    orientation: "太陽能板方向",
    panel_type_placeholder: "請選擇面板類型",
    // Solar Panel Efficiency default value
    panel_efficency_placeholder: "20 (預設)",
    tilting_angle_placeholder: "請選擇傾斜角",
    orientation_placeholder: "請選擇方向",
    north: "北",
    west: "西",
    east: "東",
    south: "南 (預設)",
    northeast: "東北",
    southeast: "東南",
    southwest: "西南",
    northwest: "西北",
    default: " (預設)"
  },
  legend: {
    title: "圖例",
    unit: "KWh/m²",
    excellent: "極佳（>1400千瓦時/平方米）",
    good: "好（1200-1400千瓦時/平方米）",
    fair: "普通（800-1200千瓦時/平方米）",
    poor: "差（<800 千瓦時/平方米）"
  },
  basemap: {
    title: "選擇地圖",
    shp: "地圖",
    raster: "正射影像圖"
  },
  message: {
    error: "錯誤了！請檢查您的網絡並稍後重試。",
    drawError: "錯誤！請檢查你繪畫的圖形是否在建築物/公眾空間內。",
    alertMessage: {
      message: `
      本網站列載的資料由中華人民共和國香港特別行政區政府（"政府"）轄下的機電工程署編製，只供一般參考。對於因或就本網站所載的任何資料（包括數據或程式）而引起的任何損失或損害，政府並不承擔責任。政府保留權利，可隨時運用其絕對酌情決定權，省略、暫停或編輯本網站所載由政府編製的資料，而無須給予任何理由，亦無須事先通知。使用者有責任自行評估本網站所載的一切資料，並宜加以核實，在根據該等資料行事之前徵詢獨立意見。
      <br />
      <br />
      The information contained in this website is compiled by the Electrical and Mechanical Services Department of the Government of the Hong Kong Special Administrative Region of the People's Republic of China (“the Government”) for general information only. The Government is not responsible for any loss or damage whatsoever arising out of or in connection with any information including data or programmes on this website. The Government reserves the right to omit, suspend or edit all information compiled by the Government in this website at any time in its absolute discretion without giving any reason or prior notice. Users are responsible for making their own assessment of all information contained in this website and are advised to verify such information by obtaining independent advice before acting upon it.
      `,
      confirmTitle: "免責聲明 Disclaimer",
      confirmButtonText: "我接受 (I accept)",
      cancelButtonText: "我不接受 (I do not accept)",
      confirmButtonTextWithoutChinese: "OK"
    },
    containZero: "你所畫的範圍包括一些不適合安裝太陽能系統的地方。",
    notNumber: "輸入非法，請輸入數字",
    capacityError: "你所輸入的安裝容量大於預計安裝容量。",
    village:
      "如果這是新界豁免管制屋宇(俗稱'村屋')，太陽能發電系統的安裝應參考地政總署發出的《興建新界豁免管制屋宇須知》或屋宇署發出的《無僭建村屋安居又幸福》小冊子中規定的要求。",
    fitIncome: "預計發電容量大過1 MW，請聯繫電力公司商討上網電價。"
  },
  buildingPopup: {
    totalRoofArea: "建築屋頂總面積",
    areaSuitableForInstallation: "適合安裝太陽能板面積",
    averageSolar: "年均太陽輻照量",
    installationCapacity: "預計發電容量",
    expectedAnnualElectricityGeneration: "預計每年發電量",
    expectedAnnualFiTIncome: "預計每年上網電價收入",
    title: "建築物資料",
    info:
      "下表中顯示的信息是基於效率百分之20的太陽能板於0度下的計算結果， 有關其他角度的詳細結果，請選用繪畫。",
    openSpaceTitle: "公眾空間資料",
    openSpaceArea: "公眾空間總面積",
    openSpaceType: "類型"
  },
  units: {
    m2: "平方米",
    "kWh/m2": "千瓦時/平方米",
    kW: "千瓦",
    kWh: "千瓦時",
    hk$: "元"
  },
  north: "北",
  noOpenSpace: "其他公眾空間的太陽能板傾斜角度選項即將推出。"
};
