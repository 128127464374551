import logoENG from "@/assets/images/logo-eng.jpg";

export default {
  systemLogo: logoENG,
  reNetLink: "https://re.emsd.gov.hk/english/index.html",
  systemMenus: [
    { index: "1", name: "Home", icon: "shouye" },
    { index: "2", name: "Contact Us", icon: "mail" },
    { index: "3", name: "Notice", icon: "Disclaimer" }
  ],
  systemTitle: "Hong Kong Solar Irradiation Map",
  subTitle: "for Building Rooftops and Open Spaces",
  guidelineLink: "./files/guideline_en.pdf",
  payback_card: {
    title: "Payback Calculator",
    installation_capacity_label: "Generation Capacity (kW)",
    installation_capacity_placeholder: "Enter",
    installation_cost_label: "Installation Cost (HK$)",
    installation_cost_placeholder: "Enter",
    annual_electricity_generation: "Estimated Annual Electricity Generation",
    income: "Estimated Annual FiT Income",
    payback_period: "Estimated Payback Period (Year)"
  },
  solar_result_card: {
    title: "Result of Selected Area",
    area_selected: "Area Selected",
    area_suitable_for_PV_installation: "Area Suitable for PV Panel Installation",
    installation_capacity: "Estimated Generation Capacity",
    electricity_generation: "Estimated Annual Electricity Generation",
    Fit_income: "Estimated Annual FiT Income",
    payback_button: "Payback Calculator"
  },
  search: {
    placeholder: "Search for a place",
    address: "Address",
    place_name: "Place Name"
  },
  topographic_map_label: {
    lang: "en",
    layer_title: "Digital Map Label"
  },
  topographic_map: {
    layer_title: "Digital Map",
    attributions:
      "<a class='attr-text' href='https://api.portal.hkmapservice.gov.hk/disclaimer' target='_blank'>&copy; Map from Lands Department</a><div class='attr-logo'></div>"
  },
  satelite_map: {
    layer_title: "Digital Image Map",
    attributions:
      "<a class='attr-text' href='https://api.portal.hkmapservice.gov.hk/disclaimer' target='_blank'>&copy; Aerial Photograph from Lands Department</a><div class='attr-logo'></div>"
  },
  draw_tool: {
    title: "Draw and Calculate",
    rec: "Rectangle",
    polygon: "Draw",
    clear: "Clear"
  },
  solor_layer_switcher: {
    transparency: "Solar Irradiation Layer Transparency",
    // transparency: "Transparency",
    transparency_placeholder: "Chooese Solar Irradiation Layer Transparency",
    title: "Options",
    solar_layer_switch: "Solar Irradiation Layer (Building Rooftops)",
    solar_layer_switch_building: "Solar Irradiation Layer (Building Rooftops)",
    solar_layer_switch_open_space: "Solar Irradiation Layer (Open Spaces)",
    building_layer_switch: "Building Layer",
    panel_type: "PV Panel Type",
    panel_efficency: "PV Panel Efficiency",
    tilting_angle: "PV Panel Tilting Angle",
    orientation: "PV Panel Orientation",
    panel_type_placeholder: "Chooese Panel Type",
    // panel_efficency_placeholder: "Enter Panel Efficency",

    // PV Panel Efficiency default value
    panel_efficency_placeholder: "20 (Default)",
    tilting_angle_placeholder: "Choose Tilting Angle",
    orientation_placeholder: "Choose Orientation",
    north: "North",
    west: "West",
    east: "East",
    south: "South (Default)",
    northeast: "Northeast",
    southeast: "Southeast",
    southwest: "Southwest",
    northwest: "Northwest",
    default: " (Default)"
  },
  legend: {
    title: "Legend",
    unit: "KWh/m²",
    excellent: "Excellent (>1400kWh/m²)",
    good: "Good (1200-1400kWh/m²)",
    fair: "Fair (800-1200kWh/m²)",
    poor: "Poor (<800kWh/m²)"
  },
  basemap: {
    title: "Basemap",
    shp: "Topo Map",
    raster: "Ortho Map"
  },
  message: {
    drawError: "Error! Please check your drawing, it must be inside a building or open space.",
    error: "Error! Please check your network and try again later.",
    alertMessage: {
      message: `
      本網站列載的資料由中華人民共和國香港特別行政區政府（"政府"）轄下的機電工程署編製，只供一般參考。對於因或就本網站所載的任何資料（包括數據或程式）而引起的任何損失或損害，政府並不承擔責任。政府保留權利，可隨時運用其絕對酌情決定權，省略、暫停或編輯本網站所載由政府編製的資料，而無須給予任何理由，亦無須事先通知。使用者有責任自行評估本網站所載的一切資料，並宜加以核實，在根據該等資料行事之前徵詢獨立意見。
      <br />
      <br />
      The information contained in this website is compiled by the Electrical and Mechanical Services Department of the Government of the Hong Kong Special Administrative Region of the People's Republic of China (“the Government”) for general information only. The Government is not responsible for any loss or damage whatsoever arising out of or in connection with any information including data or programmes on this website. The Government reserves the right to omit, suspend or edit all information compiled by the Government in this website at any time in its absolute discretion without giving any reason or prior notice. Users are responsible for making their own assessment of all information contained in this website and are advised to verify such information by obtaining independent advice before acting upon it.
      `,
      confirmTitle: "免責聲明 Disclaimer",
      confirmButtonText: "我接受 (I accept)",
      cancelButtonText: "我不接受 (I do not accept)",
      confirmButtonTextWithoutChinese: "OK"
    },
    containZero:
      "Your drawing contains area of low solar irradiation which is not suitable for PV installation",
    notNumber: "invalid input! Number is required!",
    capacityError:
      "Input of installation capacity is larger than the suggested maximum installation capacity.",
    village:
      'If this is a New Territories Exempted House (NTEH, also known as \'village house\'), the PV system installation shall refer to the requirements as stipulated in the booklets "Building New Territories Exempted Houses" published by the Lands Department or "Village Houses without unauthorized building works put your mind at ease!" published by the Buildings Department.',
    fitIncome:
      "The estimated generation capacity is greater than 1 MW, please approach the power supply company to discuss the FiT rate."
  },
  buildingPopup: {
    totalRoofArea: "Total Roof Area",
    areaSuitableForInstallation: "Area Suitable for PV Panel Installation",
    averageSolar: "Average Annual Solar Irradiation",
    installationCapacity: "Estimated Generation Capacity",
    expectedAnnualElectricityGeneration: "Estimated Annual Electricity Generation",
    expectedAnnualFiTIncome: "Estimated Annual FiT Income",
    title: "Building Information",
    info:
      "The information shown in this table is calculated based on the solar panel at 0° with solar panel efficiency of 20%. For detailed results of other angles, please draw and calculate.",
    openSpaceTitle: "Open Space Information",
    openSpaceArea: "Total Area",
    openSpaceType: "Type"
  },
  units: {
    m2: "m²",
    "kWh/m2": "kWh/m²",
    kW: "kW",
    kWh: "kWh",
    hk$: "HKD"
  },
  north: "N",
  noOpenSpace: "Other panel tilting angle options of open spaces are coming soon."
};
