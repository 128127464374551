import { GeoMapBaseUrl, ServiceHost } from "@/config/";

const state = {};
const mutations = {
  set_landsd_api_key: (state, landsdApiKey) => {
    state.landsdApiKey = landsdApiKey;
  }
};
const actions = {};
const getters = {
  landsdApiKey: () => {
    return "9100b5ce030a4b04be9681612cfb5e75";
  },
  WMSBaseUrl: () => {
    return `${GeoMapBaseUrl}/geoserver/solarMap/wms?`;
  },
  WMTSBaseUrl: () => {
    return `${GeoMapBaseUrl}/geoserver/gwc/service/wmts`;
  },
  WMGwcSBaseUrl: () => {
    return `${GeoMapBaseUrl}/geoserver/solarMap/wms?`;
  },
  WFSBaseUrl: () => {
    return `${GeoMapBaseUrl}/geoserver/solarMap/wfs?`;
  },
  remoteHost: () => {
    return `${ServiceHost}`;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
